////////////////////////////////////
/// Fit Content Height Modal

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }
    --border-radius: 10px;

    // --height: auto;
    --height: fit-content;
    --max-width: 85vw;
    --max-height: 80vh;
    --width: 52.75rem;
    --box-shadow: none;

    .ion-page {
        position: relative;
        display: flex;
        contain: content;
        max-height: 80vh;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }

    ion-header {
        padding: 1.25rem;

        &::after {
            background-image: none;
        }

        ion-toolbar {
            --min-height: unset;
        }

        ion-title {
            padding-left: 0;
        }

        ion-button {
            margin: 0;
            --padding-start: 0;
            --padding-end: 0;
            height: 1.5rem;
        }
    }

    ion-footer {
        width: calc(100% - 2.5rem);
        gap: 1.25rem;
        border-top: 1px solid #e7e7e7;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        justify-content: flex-end;

        &::before {
            background-image: none;
        }
    }
}

.modal-padding {
    padding: 1.25rem;
}
