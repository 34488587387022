.area-chart {
    .apexcharts-grid-borders,
    .apexcharts-gridlines-horizontal {
        line {
            stroke-dasharray: 5, 5;
            stroke: #e9e9e9;
        }
    }

    .apexcharts-text {
        color: #666;
    }

    .tooltip-heading {
        color: #666;
        font-size: 0.7813rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1181rem;
        letter-spacing: -0.383px;
    }

    .tooltip-subheading {
        color: #172655;
        font-size: 1.2813rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5973rem;
    }

    .tooltip-color {
        &--green {
            color: #01e573;
            background-color: #01e5731a;
        }

        &--blue {
            color: #2151ec;
            background-color: #2151ec1a;
        }
    }
}
