////////////////////////////////////
/// helper classes

.card-shadow-v2 {
    box-shadow: 0 4px 10px 0 rgba(#000, 0.04);
}

////////////////////////////////////
/// Card V2

.card-v2 {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    border-radius: 0.5rem;
    margin: 1.25rem 1.875rem;
    overflow: visible;
    contain: none;

    ion-card-header {
        min-height: 4.375rem;
        padding: 0 1.875rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f1f2;
        text-transform: capitalize;
    }

    // direct second child of the ai card component
    // in simple terms this is the wrapping container of the main content
    // which can be anything. a form, div, menu-content, etc
    > :nth-child(2) {
        padding: 1.875rem;
        display: inline-block;
        width: 100%;
    }

    ion-footer {
        padding: 1.125rem 1.75rem;
        display: flex;
        justify-content: flex-end;
    }

    ion-button {
        font-size: 0.875rem;
        --padding-top: 0.75rem;
        --padding-bottom: 0.75rem;
        --padding-start: 1.75rem;
        --padding-end: 1.75rem;
    }
}

////////////////////////////////////
/// Card V3

.card-v3 {
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    @extend .card-shadow-v2;
}
