.rounded-progress-bar {
    border-radius: 1.5rem;

    .mat-progress-bar-buffer {
        background-color: #f3f3f3;
    }

    .mat-progress-bar-fill::after {
        border-top-right-radius: 5px 50%;
        border-bottom-right-radius: 5px 50%;
    }
}
