.shimmer {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(-45deg, #f0f0f0 25%, #e4e4e4 50%, #f0f0f0 75%);
        background-size: 200% 100%;
        animation: shimmerAnimation 2s infinite;
    }
}

@keyframes shimmerAnimation {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}
