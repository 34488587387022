@import './base/base';
@import './base/utilities';

@import './components/table';
@import './components/modal';
@import './components/stepper';
@import './components/button';
@import './components/form-field';
@import './components/select';
@import './components/card';
@import './components/progress-bar';
@import './components/charts';
@import './components/shimmer';

@import './themes/material-theme';

.primary {
    color: var(--ion-color-primary);
}
.danger {
    color: var(--ion-color-danger);
}
.success {
    color: var(--ion-color-success);
}
ion-button {
    text-transform: capitalize;
}
//end default system changes
.button-center {
    margin: 0 auto;
    text-align: center;
}

.dashboard-tabs {
    display: flex;
    div {
        cursor: pointer;
    }
    width: 100%;

    padding: 5px;
    overflow-x: auto;
    @media (max-width: 599px) {
        flex-wrap: wrap;
    }
}
.asterisk-if-mandatory::after {
    content: ' *';
}
.dashboard-tabs ion-button {
    margin-bottom: 10px;
}
.tab-div-selected {
    padding: 10px;
    background-color: white;
    text-align: center;
    width: 50%;
    border-bottom: 2px solid #0052f5;
}
.tab-div {
    padding: 10px;
    background-color: white;
    text-align: center;
    width: 50%;
}
.menu-container {
    display: flex;

    justify-content: space-between;
    padding: 5px;
    overflow-x: auto;
    @media (max-width: 599px) {
        flex-wrap: wrap;
    }
}

.menu-container ion-button {
    flex-basis: calc(50% - 10px);
    margin-bottom: 10px;
}

.bottom-border {
    border-bottom: 1px solid lightgray;
}

em {
    font-size: 14px;
    //float: right;
    //padding-right: 14px;
    color: var(--ion-color-danger) !important;
}

fa-icon {
    font-size: 24px;
    min-width: 28px;
    max-width: 28px;
}

.tp-logo-image {
    content: url('../assets/tenantpay.png');
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.kyc-logo {
    height: 60px;
}

.form-grid {
    background: white;
    ion-input,
    ion-textarea,
    ion-select,
    ion-datetime,
    input[type='date'],
    select,
    input {
        border: 1px solid #d5d7da;
        border-radius: 4px;
        width: 100%;
    }
    select {
        height: 41px;
        width: 100%;
    }
    input {
        padding: 10px;
    }
    ion-datetime {
        padding: 10px;
    }
    ion-col {
        ion-label {
            font-size: small;
            font-weight: bold;
            white-space: pre;
        }
    }
    .inline-input-label {
        display: flex;
        ion-input {
            width: auto;
            display: inline-block;
        }
        .label-div {
            display: inline-block;
            background-color: lightgray;
            text-align: center;
            padding: 10px;
        }
    }
}

.max-width-col {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px; //your max size here
}

.responsive-section-row {
    max-width: 1024px;
}

.responsive-section {
    background-color: white;
    .header {
        padding: 10px;
        padding-left: 0px;
        font-weight: bold;
        ion-col {
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .bold-row {
        padding-bottom: 10px;
        padding-top: 10px;
        font-weight: bold;
    }
    .search-bar {
        border: 1px solid lightgray;
        margin-bottom: 20px;
        border-bottom: none;
    }
    .show-on-small {
        display: none;
    }
    ion-row {
        border-bottom: 1px solid lightgray !important;
    }
    ion-item {
        border-bottom: 1px solid lightgray !important;
    }
    .no-border {
        border: none !important;
    }
    .entry {
        padding: 10px;
    }
    max-width: 1024px;
    border: 1px solid lightgray;
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    @media only screen and (max-width: 800px) {
        .hide-on-small {
            display: none;
        }
        .show-on-small {
            display: initial;
        }
        .header {
            display: none;
        }
        .search-bar {
            margin-bottom: 0px;
            margin: 5px;
        }
        border: none !important;
        margin: unset;
        padding: 10px;
        // padding-top: 10px;
        border-radius: unset;
    }
    ion-row:last-child {
        border-bottom: none !important;
    }
    ion-item:last-child {
        border-bottom: none !important;
    }
}

.kyc-section {
    ion-row {
        border-bottom: none !important;
    }
    border: none;
}

.no-bottom-borders {
    ion-row {
        border-bottom: none !important;
    }
    ion-item {
        border-bottom: none !important;
    }
}

.line-tabs {
    padding-top: 10px;
    padding-bottom: 10px;

    max-height: 60px;
    overflow-y: hidden;

    ion-col {
        text-align: center;
        color: gray;
        margin-left: 20px;
    }

    ion-label {
        padding-bottom: 6px;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
        color: var(--ion-color-medium);
        background-color: transparent; /* Set background to transparent */
        border: 1px solid var(--ion-color-medium); /* Add a border */
        border-radius: 4px; /* Add border-radius for a button-like appearance */
    }

    .selected-tab {
        border-bottom: 2px solid var(--ion-color-primary) !important;
        color: white !important; /* Change the text color to white */
        background-color: var(--ion-color-primary) !important; /* Change the background color to blue */
        ion-label {
            color: white !important;
            background-color: var(--ion-color-primary) !important;
        }
    }
}

.x-scroll {
    min-width: 700px;
}

.light-background {
    --background: #f9f9fc;
    background-color: #f9f9fc;
    height: 100%;

    // @media only screen and (max-width: 800px) {
    //     --background: white;
    //     background-color: white;
    // }
}

.full-height-modal {
    --max-height: 100% !important;
    --height: 100% !important;
    @media only screen and (min-width: 800px) {
        --min-width: 800px;
        --max-width: 800px;
    }
}

.profile-pic {
    width: 70px;
    height: 70px;
    ion-icon {
        font-size: 55px;
        margin-left: 10px;
        margin-top: 5px;
    }
}
ol {
    list-style: decimal !important;
}
.btn-col {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
}
.option-modal {
    --width: 200px;
    --height: 300px;
}
.span-col {
    display: block;
    height: 15px;
}
.title-header {
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 5px;
    margin-top: 16px !important; //
    width: calc(100% + 18px);
    color: #0052f5;
    border-bottom: 1px solid #0052f5;
    text-transform: capitalize;
}
.small-toast {
    // width: 300px;
    text-align: center;
    // right: 0;
}

ion-segment-button {
    text-transform: capitalize;
    font-size: 16px;
}

.non-editable {
    border: 1px solid #d5d7da;
    padding: 10px;
    border-radius: 4px;
    margin-top: 2px;
    background-color: #d5d7da;
}

.text-area-div {
    white-space: pre-line;
}

.disabled-input {
    background-color: #d5d7da;
}
.link {
    text-decoration: underline;
    cursor: pointer;
}

.note-alert {
    textarea.alert-input.sc-ion-alert-ios,
    textarea.alert-input.sc-ion-alert-md {
        min-height: 250px !important;
    }
    .alert-wrapper.sc-ion-alert-ios,
    .alert-wrapper.sc-ion-alert-md {
        --max-width: 300px !important;
        --min-width: 300px !important;
    }
}

////////////////////////////////////
/// root element responsiveness

html {
    font-size: 100%; // defaults to 16px

    @media (min-width: 2160px) {
        font-size: 110%; // defaults to 17.6px
    }

    @media (max-width: 599px) {
        font-size: 93.75%; // defaults to 15px
    }

    @media (max-width: 450px) {
        font-size: 90.625%; // defaults to 14.5px
    }

    @media (max-width: 400px) {
        font-size: 87.5%; // defaults to 14px
    }

    @media (max-width: 350px) {
        font-size: 84.375%; // defaults to 13.5px
    }

    @media (max-width: 320px) {
        font-size: 81.25%; // defaults to 13.5px
    }
}

////////////////////////////////////
/// info grid V2
///
///

.info-grid-v2 {
    width: 100%;

    &,
    ion-row,
    ion-col {
        padding: 0;
    }
    ion-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem 0;
    }

    ion-row.align-items-baseline {
        display: flex;
        align-items: baseline;
        gap: 2rem 0;
    }

    ion-col:not(:last-child) {
        @media (min-width: 576px) {
            padding-right: 1rem;
        }
    }
}

////////////////////////////////////
/// form field v2

app-form-field ion-item {
    --highlight-color-valid: #0052f5;
}

.form-v2 {
    display: flex !important;
    flex-direction: column;
    row-gap: 0.25rem;

    &__item {
        --min-height: unset;
        --inner-border-width: 0;
        --padding-start: 0;
        --inner-padding-end: 0;
        --highlight-color-valid: #0052f5;

        font-size: 0.8125rem;

        &--radio-checkbox {
            --padding-start: 0.5rem;
            margin-left: -0.5rem;
        }

        &--input {
            --border-width: 1px;
            --border-radius: 0.386rem;
        }

        ion-input,
        ion-select {
            --padding-start: 0.75rem !important;
            --padding-end: 0.75rem !important;
            background-color: #f9f9f9;
        }

        ion-label {
            margin: 0;
            margin-left: 0.5rem;
            color: #737373;
            padding: 8px 8px 8px 0;
        }

        ion-checkbox {
            --size: 1.125rem;
            margin: 0;
        }

        ion-radio {
            margin: 0;
            transform: scale(0.9);
        }
    }

    &__field-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem 1.25rem;
    }
}

////////////////////////////////////
/// a replica of ion-input UI for normal input
/// this is done for ngx-mask compatibility

.ion-input-replica {
    padding: 10px 0.75rem;
    background-color: #f9f9f9;
    border-radius: 0.386rem;
    width: 100%;
    transition: all 0.3s;

    &:focus {
        box-shadow: inset 0px -1px 0px 0px #0052f5;
        outline: none;
    }

    &.ng-dirty.ng-invalid:focus {
        box-shadow: inset 0px -1px 0px 0px var(--ion-color-danger);
    }
}

// prevents the browser from changing the field background color after autofilling
input:-webkit-autofill {
    box-shadow: 0 0 0 30px #fff inset !important;
}

// remove the arrows from number input field
.input-arrow-hidden input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
}

/*toolbar title for save Modals heading*/
.toolbar-title {
    height: auto;
    line-height: normal;
}

.no-borders {
    border: none;
}
.mat-paginator-range-label {
    color: #0052f5;
}

/* .mat-paginator-icon {
    color: #0052f5;
} */
.ck-editor__editable {
    max-height: 350px;
}

.custom-next-button {
    // @extend %base-btn2;
    @extend .btn__generic;
    margin-top: 15px;
    padding: 10px 10px;
}
.custom-cancel-button {
    border: none !important;
    background-color: white !important;
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.63px;

    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #111;
    }
    margin-top: 15px;
    // margin-left: 9px;
    margin-left: -15px !important;
    margin-right: 10px;
}
.custom-back-button {
    @extend .btn__generic_white;
    margin-top: 15px;

    //margin-left: 10px !important;
    margin-right: 5px !important;
}
.shepherd-footer {
    margin-top: 5px;
    border-top: 0.5px solid rgb(184, 180, 180);
    justify-content: space-between !important;
}

.shepherd-header {
    background-color: white !important;
    display: flex !important;
    justify-content: space-between !important;
    .shepherd-title {
        font-weight: 700 !important;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
        color: black;
        flex: 1 0 auto !important;
        display: contents !important;
        .step-number {
            border: none !important;
            background-color: white !important;
            color: black !important;
            margin-right: 0px;
        }
    }

    height: 3rem;
}
.custom-default-class {
    border: 3px solid #0052f5;
    padding: 10px !important;
}
.custom-class-name-1 {
    margin-left: 10px;
}

.clickable-text {
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.63px;

    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #111;
    }
}

ul {
    list-style: disc !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0052f5;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #0052f5;
    background-color: #0052f5;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: rgb(255, 37, 37, 0.26);
}

.mat-radio-label {
    align-items: flex-start !important;
    white-space: normal !important;
}

.mat-form-field.ng-dirty {
    .mat-form-field-outline {
        color: #7fe26a;
    }
}
.mat-form-field.mat-focused {
    .mat-form-field-outline {
        color: #7fe26a;
    }
}

.mat-form-field.ng-dirty.ng-invalid {
    .mat-form-field-outline {
        color: red !important;
    }
}
