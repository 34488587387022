.stepper {
    display: flex !important;
    overflow: auto;

    .mat-horizontal-stepper-wrapper {
        overflow: auto;
        flex: 1;
    }

    .mat-horizontal-stepper-header-container {
        width: 40rem;
        margin: 0 auto;
    }

    .mat-horizontal-content-container {
        overflow: auto;
        //padding: 0 1.25rem;
        margin-bottom: 1.5rem;
    }

    .mat-step-header {
        padding: 0 1.5rem 1.5rem 1.5rem;
        margin-bottom: 2.5rem;
        pointer-events: none;

        &::before,
        &::after {
            width: calc(50% - 0.75rem) !important;
            top: 0.75rem;
        }
    }

    .mat-stepper-horizontal-line {
        top: 0.75rem;
    }

    .mat-step-icon-state-number {
        background-color: #f1f1f1;
        border: 1px solid #e7e7e7;
        color: #787e90;
    }

    .mat-step-icon-selected {
        background-color: transparent;
        color: #0052f5;
        border: 1px solid currentColor;
    }
}

.hide-breadcrumb {
    .mat-horizontal-stepper-header-container {
        display: none;
    }
}
