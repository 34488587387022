$COLOR-PRIMARY: #0052f5;

.table-v2 {
    width: 100%;
    height: 45rem;

    @media (max-height: 52.5em) {
        height: 26.25rem;
    }

    //////////////////////////////////
    /// root container

    .ag-root {
        // padding: 0 1.25rem;
        row-gap: 0.8rem;
    }

    .ag-root-wrapper {
        border: none;
    }

    .ag-header-viewport {
        background-color: #fff;
    }

    //////////////////////////////////
    /// header

    .ag-header {
        border: none;
    }

    .ag-header,
    .ag-header-row {
        height: 1rem !important;
        min-height: unset !important;
    }

    .ag-header-cell-text {
        font-size: 0.6875rem;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: 0.54px;
        color: #c2c2c2;
        text-transform: uppercase;
    }

    //////////////////////////////////
    /// rows

    .ag-row {
        border-width: 0;
        border-top-width: 1px;
        border-color: #e8e8e8;
        background-color: #fff;
    }

    //////////////////////////////////
    /// cell

    .ag-cell {
        display: flex;
        align-items: center;
    }

    .ag-cell-wrapper {
        width: 100%;
    }

    .ag-cell-value {
        color: #444444;
        font-size: 0.875rem;
        font-weight: 600;
    }

    .ag-cell-focus,
    .ag-cell-no-focus {
        border: none !important;
    }

    //////////////////////////////////
    /// pagination

    .ag-paging-panel {
        justify-content: space-between;
        border-top: none;
    }

    .ag-paging-row-summary-panel {
        color: $COLOR-PRIMARY;
    }

    .ag-disabled {
        .ag-icon {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 0.25rem;
            font-size: 0.75rem;
            color: gray;

            &.ag-icon-first,
            &.ag-icon-last {
                background-color: gray;
                color: white;
            }

            &.ag-icon-next,
            &.ag-icon-previous {
                border: 1px solid currentColor;
            }
        }
    }

    .ag-icon {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0.25rem;
        font-size: 0.75rem;
        color: $COLOR-PRIMARY;

        &.ag-icon-first,
        &.ag-icon-last {
            background-color: $COLOR-PRIMARY;
            color: white;
        }

        &.ag-icon-next,
        &.ag-icon-previous {
            border: 1px solid currentColor;
        }
    }

    //////////////////////////////////
    /// overlay

    .ag-overlay-no-rows-wrapper {
        font-size: 1.5rem;
        color: #ff6969;
    }
}

// status tag
.status-tag {
    .ag-cell-value span {
        padding: 0.375rem 0.625rem;
        height: 32px;
        width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 1.375rem;

        font-size: 0.875rem;
        font-weight: 400;
    }

    &--active .ag-cell-value span {
        background-color: rgba(#00e472, 0.12);
        color: #00e472;
    }

    &--pending .ag-cell-value span {
        background-color: rgba(#ffc107, 0.12);
        color: #ffc107;
    }

    &--inactive .ag-cell-value span {
        background-color: rgba(#ff6969, 0.12);
        color: #ff6969;
    }
}

// table heading container
[twf-heading] {
    padding: 1.25rem 0;
}

// table heading filters
[twf-filters] {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    padding: 1rem 1.25rem;
    border-bottom-width: 1px;

    @media (max-width: 599px) {
        flex-direction: column;
    }

    @media (max-width: 280px) {
        flex-wrap: nowrap;
    }
}

[responsive-div] {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    padding: 1rem 1.25rem;

    @media (max-width: 599px) {
        flex-direction: column;
        align-items: normal !important;
        padding: 0;
    }
}
