.mat-select-arrow-wrapper {
    transform: unset !important;

    .mat-select-arrow {
        $icon-size: 1.5rem;

        border: 0;
        margin: 0;
        background-image: url('/assets/icon/caret.png');
        background-size: $icon-size $icon-size;
        width: $icon-size;
        height: $icon-size;
    }
}
