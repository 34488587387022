////////////////////////////////////
/// button classes

// using hex codes directly here instead of declaring it in theme
// as doing so will affect the whole portal
// this will be updated in future

%base-btn {
    --background: #0052f5;
    --border-radius: 0.5rem;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1.875rem;
    --padding-end: 1.875rem;

    height: auto;
    font-size: 1.125rem;
    position: relative;
    z-index: 0;

    // new changes
    font-size: 0.875rem;
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;
    --padding-start: 1rem;
    --padding-end: 1rem;

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 2.75px;
        left: 50%;
        transform: translateX(-50%);
        width: 86.5%;
        height: 100%;
        background-color: #00e472;
        border-radius: 0.625rem;
        z-index: -1;

        // new changes
        top: 2px;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.375rem;
    }
}

%base-btn2 {
    position: relative;
    color: white !important;
    height: auto;
    background: #00e472 !important;
    border-radius: 0.625rem;
    z-index: 0;
    padding-top: 4px !important;
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        background: #0052f5 !important;
        left: 50%;
        transform: translateX(-50%);
        width: 120%;
        height: 100%;
        border-radius: 0.5rem;
        z-index: -1;

        bottom: 2px;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.375rem;
    }
}

%base-btn3 {
    position: relative;
    color: white !important;
    height: auto;
    background: #00e472 !important;
    border-radius: 0.625rem;
    z-index: 0;
    padding-top: 4px !important;
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        background: black !important;
        left: 50%;
        transform: translateX(-50%);
        width: 120%;
        height: 100%;
        border-radius: 0.5rem;
        z-index: -1;
        bottom: 2px;
    }
}
%base-btn4 {
    position: relative;
    color: black !important;
    height: auto;
    background: #00e472 !important;
    border-radius: 0.625rem;
    z-index: 0;
    padding-top: 4px !important;
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        background: white !important;
        border-color: #0052f5;
        border-style: solid;
        border-width: 1px;
        left: 50%;
        transform: translateX(-50%);
        width: 120%;
        height: 100%;
        border-radius: 0.5rem;
        z-index: -1;
        bottom: 2px;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.375rem;
    }
}

.btn {
    &__generic {
        @extend %base-btn2;
    }
    &__generic_black {
        @extend %base-btn3;
    }
    &__generic_white {
        @extend %base-btn4;
    }

    &__primary {
        @extend %base-btn;
        // --box-shadow: unset;
    }

    &__primary-outlined {
        @extend %base-btn;
        --background: #fff;
        --border-color: #0052f5;
        --border-style: solid;
        --border-width: 1px;
        color: black;
    }

    &__accent {
        @extend %base-btn;
        --background: #00e472;
    }

    &__warn {
        @extend %base-btn;
        --background: #ff6969;
    }
}
