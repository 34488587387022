mat-label {
    color: #666;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mat-form-field {
    color: black;
    font-weight: 500;
    font-size: 0.8125rem;
}

.mat-form-field-infix {
    border-top-width: 0.3em;
    padding: 0.8em 0;
    line-height: 1.2;
}

mat-error {
    line-height: 1.3;
}

///////////////////////////////////////////////////////
//// fill-container, a class used for making
//// the height of text area inside a mat form field
//// 100% to the container.

.mat-form-field.fill-container,
.mat-form-field.fill-container .mat-form-field-infix,
.mat-form-field.fill-container .mat-form-field-flex,
.mat-form-field.fill-container .mat-form-field-wrapper {
    height: 100%;
    width: 100%;
    padding-bottom: 0;
}

.mat-form-field.fill-container textarea {
    height: calc(100% - 6px);
}

//// end of fill-container
///////////////////////////////////////////////////////

.no-error-field .mat-form-field-wrapper {
    margin-bottom: -1.25em !important;
}

///////////////////////////////////////////////////////
//// search bar field

.search-bar-field {
    .mat-form-field-flex {
        align-items: center;
        gap: 0.625rem;
    }

    .mat-form-field-prefix {
        top: 0.125em !important;
        color: #787e90;
    }

    .mat-form-field-outline::after {
        content: 'Search';
        background-color: #0052f5;
        color: white;
        border-radius: 0 5px 5px 0;

        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.75rem 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    input.mat-input-element {
        width: calc(100% - 80px); // rough average width of search button
    }
}

//// end of fill-container
///////////////////////////////////////////////////////
